<template>
  <div>
    <page-title
      :heading="$t('generic.lang_replication_templates')"
      :subheading="$t('generic.lang_replication_templates')"
      :icon="icon"
      show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <replication-template-create-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import ReplicationTemplateCreateComponent from "../../components/posReplication/replicationTemplate/ReplicationTemplateCreateComponent.vue";

export default {
  components: {
    PageTitle,
    ReplicationTemplateCreateComponent,
  },
  data: () => ({
    icon: "pe-7s-plugin icon-gradient bg-tempting-azure",
  }),
};
</script>
